import { useEffect, useState, useMemo } from "react"
import { useParams } from "react-router-dom"
import { httpGet } from "../services/http.service";
import logo from "../assets/images/logo.png";
import { meanBy, orderBy } from "lodash";
import { Typography } from "@mui/material";
import "./pdf.css";

export const OldPdfGenerator = ()=>{
    const { userId, id,revision } = useParams<any>();
    const [data, setData] = useState<any[]>([]);
    const [question, setQuestions] = useState<any[]>([]);
    const [title, setTitle] = useState<string>('');
    const [score, setScore] = useState<any>(null);
    const [name, setName] = useState<string>('');
    const [uniqueId, setUniqueId] = useState<string>('');
    const activeQuestions = useMemo(()=>question,[question]);

    const getAnswer = (question:any)=>{
        return data.find(a=>a.question_id === +question.id)?.answer || '';
    }

    useEffect(()=>{
        (async()=>{
            if(userId && id){
                const {questions,answers, title ,score, uniqueId} = await httpGet(`/app/downloadAnswers/${userId}/${id}/${revision}`);
                setData(answers);
                setQuestions(questions);
                setTitle(title);
                setScore(score);
                setUniqueId(uniqueId);
                const result = questions.find((q:any)=>q.result_name);
                if(result){
                    setName(answers.find((a:any)=>a.question_id === +result.id)?.answer)
                }
            }
        })()
      
    },[userId])

    const getOverallText = (result:any)=>{
        const overall = meanBy(result?.groupWeight,'sss');
        const scope = result?.overall.find((v:any)=>+overall>=v.from_score && +overall<=v.to_score);
        
        return scope?.result || '';
      }

      const shouldShowQuestion = (question:any)=>{
        const {is_hidden} = question;

        if(is_hidden){
            return getAnswer(question).length >0;
        }
        
        return true;
      }

    return (
        <div>
            <div className="flex align-center justify-center p-5">
                <div><img src={logo} /></div>
                <div className=" text-center pt-7 pl-3 text-3xl font-bold">{title} <br/>by Dr. Mary Barbera</div>
            </div>
            
            <div className="columns-3 p-5">
            {activeQuestions.map((q:any,index:number)=>(
                <>
                {(index === 0 || q.group_id !== activeQuestions[index-1].group_id) && (
                    <div className="block text-green font-bold mt-3 text-l break-words">{q.question_group?.title}</div>
                )}

                    {shouldShowQuestion(q) && (
                        <div className="block text-xs">
                            {q.description}
                            <span className="pl-2 font-bold break-words"> {getAnswer(q)}</span>
                        </div>
                    )}
                </>
            ))}
            </div>
            <div className="mt-5">&nbsp;</div>
            <div style={{marginTop:32}} >
            <Typography variant="h5" className="block mt-5 pl-5 pt-2 pb-2">
                    <b>Assessment Score: </b> (#{uniqueId})
                </Typography>
           
            { orderBy(score?.groupWeight || [],'id').map((w:any)=>(
                    <div className="block pl-5 pt-3">
                      <b>{w.groupName}</b> : {(+w.sss).toFixed(0)} %
                      <div className="block">
                      {name}  {w.text}
                      </div>
                    </div>
            ))}
            <div className="block mb-10 uppercase p-5">
                <b>Overal Score</b> : {meanBy(score?.groupWeight,'sss').toFixed(0)} %
                <div className="block normal-case">
                 {name} {getOverallText(score)}
                </div>
            </div>
            <hr/>
            <div className="p-5 block" id="Disclaimer">
             <b>**Disclaimer**</b>
             <br/> 
            this tool is not a standardized assessment and these scores are for informational purposes only. If you are concerned about your child’s delays and/or problem behaviors please contact a medical and/or behavioral professional who can assist you with further assessment. But, don’t wait and worry- join us today to start turning things around at marybarbera.com. 

            </div>
            </div>
        </div>
    )
}