import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { httpGet } from "../services/http.service";
import logo from "../assets/images/logo.png";
import { meanBy } from "lodash";
import "./pdf2.css";
import "../assets/pdf-css/frontend.min.css";

export const PdfGenerator = () => {
  const { userId, id, revision } = useParams<any>();
  const [data, setData] = useState<any[]>([]);
  const [question, setQuestions] = useState<any[]>([]);
  const [title, setTitle] = useState<string>("");
  const [childName, setChildName] = useState<string>("");
  const [problemScore, setProblemScore] = useState<number>(0);
  const [overallScore, setOverallScore] = useState<number>(0);
  const [languageScore, setLanguageScore] = useState<number>(0);
  const [selfScore, setSelfScore] = useState<number>(0);
  const [flagText, setFlagText] = useState<string>("");

  const activeQuestions = useMemo(() => question, [question]);

  const getAnswer = (question: any) => {
    return data.find((a) => a.question_id === +question.id)?.answer || "";
  };

  function getFlagText(
    overallScore: number,
    selfCareScore: number,
    languageScore: number,
    problemBehaviorScore: number
  ) {
    const thresholdValue = 85;
    let scoresFlag = "";
    let flagText = "";
    if (selfCareScore < thresholdValue) {
      scoresFlag = "S";
    }
    if (languageScore < thresholdValue) {
      scoresFlag += "L";
    }
    if (problemBehaviorScore < thresholdValue) {
      scoresFlag += "P";
    }
    if (overallScore < thresholdValue) {
      scoresFlag += "O";
    }
    console.log("scoresFlag", scoresFlag);

    switch (scoresFlag) {
      case "S":
        flagText =
          "Scores closer to 100% show more strengths in those areas. Scores lower than 85% in one, two or all three areas indicate need for improvement. Based on the above results " +
          '<span class="blue-text">' +
          childName +
          "</span>" +
          " needs help in self-care skills.";
        break;
      case "L":
        flagText =
          "Scores closer to 100% show more strengths in those areas. Scores lower than 85% in one, two or all three areas indicate need for improvement. Based on the above results " +
          '<span class="blue-text">' +
          childName +
          "</span>" +
          " needs help in increasing language skills.";
        break;
      case "P":
        flagText =
          "Scores closer to 100% show more strengths in those areas. Scores lower than 85% in one, two or all three areas indicate need for improvement. Based on the above results " +
          '<span class="blue-text">' +
          childName +
          "</span>" +
          " needs help in reducing problem behaviors.";
        break;
      case "SL":
        flagText =
          "Scores closer to 100% show more strengths in those areas. Scores lower than 85% in one, two or all three areas indicate need for improvement. Based on the above results " +
          '<span class="blue-text">' +
          childName +
          "</span>" +
          " needs help in self-care skills and increasing language skills.";
        break;
      case "SLP":
        flagText =
          "Scores closer to 100% show more strengths in those areas. Scores lower than 85% in one, two or all three areas indicate need for improvement. Based on the above results " +
          '<span class="blue-text">' +
          childName +
          "</span>" +
          " needs help in self-care skills, increasing language skills, and reducing problem behaviors.";
        break;
      case "SP":
        flagText =
          "Scores closer to 100% show more strengths in those areas. Scores lower than 85% in one, two or all three areas indicate need for improvement. Based on the above results " +
          '<span class="blue-text">' +
          childName +
          "</span>" +
          " needs help in self-care skills and reducing problem behaviors.";
        break;
      case "LP":
        flagText =
          "Scores closer to 100% show more strengths in those areas. Scores lower than 85% in one, two or all three areas indicate need for improvement. Based on the above results " +
          '<span class="blue-text">' +
          childName +
          "</span>" +
          " needs help in increasing language skills and reducing problem behaviors.";
        break;
      default:
        flagText =
          "Scores closer to 100% show more strengths in those areas. Scores lower than 85% in one, two or all three areas indicate need for improvement.";
    }
    setFlagText(flagText);
  }

  useEffect(() => {
    (async () => {
      if (userId && id) {
        const { questions, answers, title, score, uniqueId } = await httpGet(
          `/app/downloadAnswers/${userId}/${id}/${revision}`
        );
        setData(answers);
        setQuestions(questions);
        setTitle(title);
        const result = questions.find((q: any) => q.result_name);
        if (result) {
          setChildName(answers.find((a: any) => a.question_id === 3)?.answer);
        }
        let problemScore = 0;
        let selfCareScore = 0;
        let languageScore = 0;
        const overallScore = +meanBy(score?.groupWeight, "sss").toFixed(0);
        setOverallScore(overallScore);
        score?.groupWeight.forEach((w: any) => {
          if (w && w.groupName === "Self-Care and Daily Living Tasks") {
            selfCareScore = +w.sss.toFixed(0);
            setSelfScore(selfCareScore);
          }
          if (w && w.groupName === "Language and Learning Skills") {
            languageScore = +w.sss.toFixed(0);
            setLanguageScore(languageScore);
          }
          if (w.groupName === "Problem Behavior ") {
            problemScore = +w.sss.toFixed(0);
            setProblemScore(problemScore);
          }
        });

        getFlagText(overallScore, selfCareScore, languageScore, problemScore);

        const meter4: any = document.querySelector(".meter4");
        if (problemScore !== null) {
          if (
            !isNaN(problemScore) &&
            problemScore >= 1 &&
            problemScore <= 100
          ) {
            meter4.style.setProperty("--value4", `${problemScore}%`);
          }
        }

        const meter: any = document.querySelector(".meter");
        if (overallScore !== null) {
          if (
            !isNaN(overallScore) &&
            overallScore >= 1 &&
            overallScore <= 100
          ) {
            meter.style.setProperty("--value", `${overallScore}%`);
          }
        }

        const meter2: any = document.querySelector(".meter2");
        if (selfCareScore !== null) {
          if (
            !isNaN(selfCareScore) &&
            selfCareScore >= 1 &&
            selfCareScore <= 100
          ) {
            meter2.style.setProperty("--value2", `${selfCareScore}%`);
          }
        }

        const meter3: any = document.querySelector(".meter3");
        if (languageScore !== null) {
          if (
            !isNaN(languageScore) &&
            languageScore >= 1 &&
            languageScore <= 100
          ) {
            meter3.style.setProperty("--value3", `${languageScore}%`);
          }
        }
      }
    })();
  }, [userId]);

  const shouldShowQuestion = (question: any) => {
    const { is_hidden } = question;

    if (is_hidden) {
      return getAnswer(question).length > 0;
    }

    return true;
  };

  return (
    <div>
      <div id="div1" className="">
        <div className="flex align-center justify-center p-5">
          <div>
            <img width={100} src={logo} />
          </div>
          <div className=" text-center pt-7 pl-3 text-3xl font-bold">
            {title} <br />
            by Dr. Mary Barbera
          </div>
        </div>
        <div className="columns-3 p-5">
          {activeQuestions.map((q: any, index: number) => (
            <>
              {(index === 0 ||
                q.group_id !== activeQuestions[index - 1].group_id) && (
                <div className="block text-green font-bold mt-3 text-l break-words">
                  {q.question_group?.title}
                </div>
              )}

              {shouldShowQuestion(q) && (
                <div className="block text-xs">
                  {q.description}
                  <span className="pl-2 font-bold break-words">
                    {" "}
                    {getAnswer(q)}
                  </span>
                </div>
              )}
            </>
          ))}
        </div>
      </div>

      <div id="div2" className="row secondPage page">
        <div
          data-elementor-type="wp-page"
          data-elementor-id={22015}
          className="elementor elementor-22015"
        >
          <div className="elementor-inner">
            <div className="elementor-section-wrap">
              <section
                data-particle_enable="false"
                data-particle-mobile-disabled="false"
                className="elementor-section elementor-top-section elementor-element elementor-element-2ba4a3d paper-bg elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="2ba4a3d"
                data-element_type="section"
                data-settings='{"background_background":"classic"}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div
                      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-22390df"
                      data-id="22390df"
                      data-element_type="column"
                    >
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div
                            className="elementor-element elementor-element-e149c05 elementor-widget elementor-widget-html"
                            data-id="e149c05"
                            data-element_type="widget"
                            data-widget_type="html.default"
                          >
                            <div className="elementor-widget-container">
                              <div id="subHeadTextDiv">
                              Barbera Early Childhood Assessment(BECA)™
                                <br /> Scores for {childName}
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-6630bb7 elementor-widget elementor-widget-html"
                            data-id="6630bb7"
                            data-element_type="widget"
                            data-widget_type="html.default"
                          >
                            <div className="elementor-widget-container">
                              <div id="scoreTextDiv">
                                Overall Score: {overallScore}%*
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-d38b935 elementor-widget elementor-widget-html"
                            data-id="d38b935"
                            data-element_type="widget"
                            data-widget_type="html.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="meter">
                                <div className="meter-value" />
                                <div className="meter-label meter-label-right">
                                  100%
                                </div>
                                <div className="meter-label meter-label-left">
                                  0%
                                </div>
                                <div className="meter-label meter-label-bottom meter-label-bottom-left">
                                  Needs
                                </div>
                                <div className="meter-label meter-label-bottom meter-label-bottom-right">
                                  Strengths
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-92980dc elementor-widget elementor-widget-html"
                            data-id="92980dc"
                            data-element_type="widget"
                            data-widget_type="html.default"
                          >
                            <div className="elementor-widget-container">
                              <div id="selfCareScoreTextDiv">
                                Self-Care and Daily Living Tasks: {selfScore}%
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-a45fe5f elementor-widget elementor-widget-html"
                            data-id="a45fe5f"
                            data-element_type="widget"
                            data-widget_type="html.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="meter2">
                                <div className="meter-value" />
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-d520a4a elementor-widget elementor-widget-html"
                            data-id="d520a4a"
                            data-element_type="widget"
                            data-widget_type="html.default"
                          >
                            <div className="elementor-widget-container">
                              <div id="languageScoreTextDiv">
                                Language and Learning Skills: {languageScore}%
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-0154b5b elementor-widget elementor-widget-html"
                            data-id="0154b5b"
                            data-element_type="widget"
                            data-widget_type="html.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="meter3">
                                <div className="meter-value" />
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-8f23919 elementor-widget elementor-widget-html"
                            data-id="8f23919"
                            data-element_type="widget"
                            data-widget_type="html.default"
                          >
                            <div className="elementor-widget-container">
                              <div id="problemScoreTextDiv">
                                Problem Behaviors: {problemScore}%
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-99cfd69 elementor-widget elementor-widget-html"
                            data-id="99cfd69"
                            data-element_type="widget"
                            data-widget_type="html.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="meter4">
                                <div className="meter-value" />
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-ee954c7 elementor-widget elementor-widget-html"
                            data-id="ee954c7"
                            data-element_type="widget"
                            data-widget_type="html.default"
                          >
                            <div className="elementor-widget-container">
                              <div id="flagTextDiv">{flagText}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="p-5 block" id="Disclaimer">
        <b>**Disclaimer**</b>
        <br />
        This tool is not a standardized assessment and these scores are for
        informational purposes only. If you are concerned about your child’s
        delays and/or problem behaviors please contact a medical and/or
        behavioral professional who can assist you with further assessment. But,
        don’t wait and worry- join us today to start turning things around at
        marybarbera.com/workshop.
      </div>
    </div>
  );
};
