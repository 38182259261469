import  React, { useState } from 'react';
import { Card, CardHeader, CardContent, Grid, TextField, Button, Divider, Alert  } from "@mui/material"
import { Link, useNavigate  } from 'react-router-dom';
import { Spinner } from '../../../ui/Spinner.component';
import { doLogin, registration } from '../../../services/authentication.service';
import { handleFormValue } from '../../../utils/form.utility';
import { GoogleLogin } from 'react-google-login';
import MicrosoftLogin from "react-microsoft-login";
import "./Login.component.scss";

export const Login = ()=>{
    let history = useNavigate();

    const [form, setForm] = useState({
        username: "",
        password: "",
    });

    const [submitted, setSubmitted] = useState(false);
    const [hasError, setHasError] = useState(false);

    const responseGoogle = async (response:any) => {
        Spinner.show();
        const { profileObj,tokenObj } = response;
        const { access_token } = tokenObj;
        const { email,familyName,givenName,googleId,imageUrl } = profileObj;
        
        if(access_token && googleId) {
            let user =  await registration({
                email,
                first_name: givenName,
                last_name: familyName,
                password: access_token,
                provider:"GMAIL",
                image_url:imageUrl,
            })
            .catch(()=>null);

            if(user) {
                user = await doLogin({
                    username:email,
                    password:access_token,
                })
                .catch(()=>null);
                Spinner.hide();
                history("/");
            }
        }

        Spinner.hide();
      }

      
    const loginClicked = async () => {
        setSubmitted(true);
        setHasError(false);
        Spinner.show();

        const user = await doLogin(form)
        .catch(()=>null);

        if (!user) {
            Spinner.hide();
            setHasError(true);
        }else{
            Spinner.hide();
            history("/");
        }
    }

    const microsoftHandler = async (err:any, data:any)=>{

        if(!err){

            const {account, accessToken,givenName,surname } = data;
            const {userName, name } = account;
            
            let user =  await registration({
                email:userName,
                first_name: givenName,
                last_name: surname,
                password: accessToken,
                provider:"MICROSOFT",
                image_url:'',
            })
            .catch(()=>null);

            if(user) {
                user = await doLogin({
                    username:userName,
                    password:accessToken,
                })
                .catch(()=>null);
                Spinner.hide();
                history("/");
            }
        }else{
            console.log(err);
        }
    }

    return(
        <Card className="login-card">
        <CardHeader title="Log in">

        </CardHeader>
        <CardContent>

            {hasError && (
                <Alert className="mb-32" severity="error">The user name or password is incorrect!</Alert>
            )}

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField error={submitted && !form.username} onChange={(e) => handleFormValue(e, "username", form, setForm)} label="Email" />
                </Grid>
                <Grid item xs={12}>
                    <TextField type="password" error={submitted && !form.password} onChange={(e) => handleFormValue(e, "password", form, setForm)} label="Password" />
                </Grid>
                
                <Grid item xs={6}>
                    <Button onClick={loginClicked} variant="contained" color="primary">
                        Login
                    </Button>
                </Grid>

                <Grid item xs={6}>
                    <Link to="/register">
                        Register
                    </Link>
                </Grid>

                <Grid item xs={12}>
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GMAIL_CLIENT_ID || ''}
                        buttonText="GMAIL"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MicrosoftLogin withUserData={true} clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID || ''} authCallback={microsoftHandler} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                
                <Grid item xs={12}>
                    <Link to="/resetpassword">
                        Forgot Password?
                    </Link>
                </Grid>

            </Grid>
        </CardContent>
    </Card>
    )
}