import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import { App } from './App';
import { Login } from './components/auth/login/Login.component';
import { Register } from './components/auth/register/Register.component';
import reportWebVitals from './reportWebVitals';
import { Spinner } from './ui/Spinner.component';
import "./index.css";
import { OldPdfGenerator } from './components/pdf-old-generator.component';
import { PdfGenerator } from './components/pdf-generator.component';
import { Terms } from './components/terms.component';
import { KeapApi } from './components/connect-keap-api.component';
import { AppNew } from './AppNew';

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <Routes >
          <Route path="/login" element={<Login/>} />
          <Route path="/old-pdf/:userId/:id/:revision" element={<OldPdfGenerator/>} />
          <Route path="/pdf/:userId/:id/:revision" element={<PdfGenerator/>} />
          <Route path="/terms" element={<Terms/>} />
          <Route path="/register"  element={<Register/>} />
          <Route path="/" element={<App/>} />
          <Route path="/new" element={<AppNew/>} />
          <Route path="/keapapi" element={<KeapApi/>} />
        </Routes >
      </Router>
      <Spinner />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
