import  React, { useState } from 'react';
import { Card, CardHeader, CardContent, Grid, TextField, Button, Divider, Alert  } from "@mui/material"
import { Link, useNavigate  } from 'react-router-dom';
import { Spinner } from '../../../ui/Spinner.component';
import { registration } from '../../../services/authentication.service';
import { handleFormValue } from '../../../utils/form.utility';

export const Register = ()=>{
    let history = useNavigate();

    const [form, setForm] = useState({
        email: "",
        first_name: "",
        last_name: "",
        password: "",
    });

    const [submitted, setSubmitted] = useState(false);
    const [hasError, setHasError] = useState(false);

    const loginClicked = async () => {
        setSubmitted(true);
        setHasError(false);
        Spinner.show();

        const user = await registration(form)
        .catch(()=>null);

        if (!user) {
            Spinner.hide();
            setHasError(true);
        }else{
            Spinner.hide();
            history("/login");
        }
    }

    return(
        <Card className="login-card">
        <CardHeader title="Register">

        </CardHeader>
        <CardContent>

            {hasError && (
                <Alert className="mb-32" severity="error">Email already exists!</Alert>
            )}

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField error={submitted && !form.email} onChange={(e) => handleFormValue(e, "email", form, setForm)} label="Email" />
                </Grid>
                <Grid item xs={12}>
                    <TextField type="password" error={submitted && !form.password} onChange={(e) => handleFormValue(e, "password", form, setForm)} label="Password" />
                </Grid>
                <Grid item xs={12}>
                    <TextField  onChange={(e) => handleFormValue(e, "first_name", form, setForm)} label="First Name" />
                </Grid>
                <Grid item xs={12}>
                    <TextField onChange={(e) => handleFormValue(e, "last_name", form, setForm)} label="Last Name" />
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={loginClicked} variant="contained" color="primary">
                        Submit
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>
                
                <Grid item xs={12}>
                    <Link to="/login">
                        Login
                    </Link>
                </Grid>

            </Grid>
        </CardContent>
    </Card>
    )
}