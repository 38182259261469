import { LoginDto } from "../types/dto/login.dto";
import { getBearerToken, setBearerToken, setUserInfo } from "../utils/local-storage.utility";
import { httpPost } from "./http.service";

export const IsUserLoggedIn = () => getBearerToken() !== "";

export const logout = () => {
    setBearerToken("");
    setUserInfo(null);
}

export const doLogin = async (loginDto: LoginDto) => {
    const loginResult = await httpPost(`/login`, loginDto)
        .catch(() => null);

    if (loginResult) {
        setUserInfo(loginResult);
        setBearerToken(loginResult.access_token);

        return loginResult;
    }

    return null;
}

export const registration = async (userDto: any) => {
    const loginResult = await httpPost(`/register`, userDto)
        .catch(() => null);

    if (loginResult) {
        setUserInfo(loginResult);
        setBearerToken(loginResult.token);

        return loginResult;
    }
    return null;
}