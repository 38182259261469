import { UserDto } from "../types/dto/user.dto";

export const getLocalStorage = (key: string) => localStorage.getItem(key)
export const setlocalStorage = (key: string, value: any) => {
    localStorage.removeItem(key);
    localStorage.setItem(key, JSON.stringify(value))

}

export const getBearerToken = () => localStorage.getItem('site-bearer-token') || ''
export const setBearerToken = (token: string) =>
    localStorage.setItem('site-bearer-token', token)

export const setUserInfo = (user: any) => {
    if (user) {
        setlocalStorage('user-info', user)
    } else {
        // setlocalStorage('user-info', user)
    }
}
export const getUserInfo = (): (UserDto | null) => {
    const user = getLocalStorage('user-info') || ''

    if (user && user.length) {
        return JSON.parse(user)
    }

    return null;
}
