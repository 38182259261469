import { Typography } from '@mui/material';

export const Terms = ()=>{
    
    return (
        <div className="p-12">
            <Typography variant="h4" className="text-center block oswald">
            Terms And Conditions
            </Typography>
            <div className='mt-5 mb-10'>
            <iframe src={"terms.pdf#toolbar=0"} width="100%"  height="600px" frameBorder="0" 
            style={{background: "#FFFFFF"}}/>
            </div>
        </div>
    )
}