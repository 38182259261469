import React, { useEffect, useState, useRef, useMemo } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import moment from "moment";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import "./App.scss";
import { IsUserLoggedIn } from "./services/authentication.service";
import { UserProfile } from "./components/profile/User-Profile.component";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Paper,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  ButtonGroup,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import { downloadReportLink, httpGet, httpPost } from "./services/http.service";
import { AnswerType } from "./types/enum";
import { orderBy, meanBy } from "lodash";
import { Spinner } from "./ui/Spinner.component";
import { isMobile } from "react-device-detect";
import { calcYearMonth, formatDate } from "./utils/date-time.utility";
import logo from "./assets/images/logo.png";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { generateUUID } from "./utils/form.utility";

const drawerWidth: number = 240;
const parentGuardian: string = "Parent/Guardian";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

export const App = () => {
  const quesionnairId = +(process.env.REACT_APP_QID || "1");
  const [open, setOpen] = useState<boolean>(false);
  const [validUser, setValidUser] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [user, setUser] = useState<number>(0);
  const [userEmail, setUserEmail] = useState<string>("");
  const [revisionId, setRevisionId] = useState<string>("");
  const [result, setResult] = useState<any>(null);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const email = useRef<any>("");

  const [emailError, setEmailError] = useState(false);

  const validateEmail = (email: string): boolean => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleEmailBlur = () => {
    const emailAddress = email.current?.value;
    if (emailAddress && !validateEmail(emailAddress)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleEmailChange = () => {
    setEmailError(false);
  };

  const fieldRef = React.useRef<HTMLInputElement>(null);
  const [formData, setFormData] = useState<any | null>(null);
  const [step, setStep] = useState<number>(0);
  const [userAnswer, setUserAnswer] = useState<any>({});
  const [checkErrors, setCheckErrors] = useState<boolean>(false);

  const questionRelevants = useMemo(
    () => formData?.questionRelevants,
    [formData]
  );

  const activeQuestions = useMemo(() => {
    return orderBy(
      formData?.questionnaire?.Question?.filter((q: any) => q.step_id === step),
      "order_id"
    ) as any[];
  }, [step, formData]);

  const resultName = useMemo(() => {
    const qid = formData?.questionnaire?.Question?.find(
      (c: any) => c.result_name
    )?.id;
    return userAnswer[qid];
  }, [formData, userAnswer]);

  const handleMultipleAnswer = (e: any, question: any) => {
    if (e?.target.checked) {
      setUserAnswer({
        ...userAnswer,
        [question.id]: (userAnswer[question.id] || []).concat(e?.target?.value),
      });

      showRelativeQuestions(question, e?.target?.value);
    } else {
      setUserAnswer({
        ...userAnswer,
        [question.id]: (userAnswer[question.id] || []).filter(
          (o: string) => o !== e?.target?.value
        ),
      });
    }
  };

  const setAnswers = (e: any, question: any) => {
    if (question.answer_type_id === 2) {
      setUserAnswer({
        ...userAnswer,
        [question.id]: formatDate(e, "MM/DD/YYYY"),
      });

      if (
        +question.id === +(process.env.REACT_APP_DOB || "4") &&
        formatDate(e, "MM/DD/YYYY") !== "Invalid date"
      ) {
        const dob = new Date(new Date(e).getTime() + 24 * 60 * 60 * 1000);
        const dobym = calcYearMonth(dob);
        setUserAnswer({
          ...userAnswer,
          [question.id]: formatDate(e, "MM/DD/YYYY"),
          5: dobym[0],
          6: Math.max(dobym[1], 1),
        });
      }
    } else {
      setUserAnswer({
        ...userAnswer,
        [question.id]:
          e?.target?.value || (question.answer_type_id === 1 ? "" : e),
      });
    }

    showRelativeQuestions(question, e?.target?.value || e);
  };

  const showRelativeQuestions = (question: any, value: any) => {
    const rel = questionRelevants.filter(
      (q: any) => q.parent_question_id === question.id
    );

    if (rel && rel.length > 0) {
      const relIds = rel.map((q: any) => q.show_question_id);

      setFormData({
        ...formData,
        questionnaire: {
          ...formData?.questionnaire,
          Question: formData.questionnaire?.Question?.filter(
            (q: any) => !relIds.includes(q.id)
          ).concat(
            formData.questionnaire?.Question?.filter((q: any) =>
              relIds.includes(q.id)
            ).map((qs: any) => ({
              ...qs,
              is_hidden: !(
                questionRelevants
                  .find((q: any) => q.show_question_id === qs.id)
                  .condition.indexOf(value) !== -1
              ),
            }))
          ),
        },
      });
    }
  };

  const submitAnswers = async () => {
    Spinner.show();
    const result = await httpPost("/app/answers", {
      userAnswer,
      userId: user,
      quesionnairId,
      revisionId,
    }).catch(() => null);

    if (!result || result.error) {
      Spinner.hide();
      alert("Something went wrong!");
      console.log(result?.error);
    } else {
      await httpGet(`/app/generatePDF/${user}/${quesionnairId}/${revisionId}`);

      const { score } = await httpGet(
        `/app/downloadAnswers/${user}/${quesionnairId}/${revisionId}`
      );
      // Spinner.hide();
      // setSubmitted(true);
      setResult(score);
      await goToUrl(score);
    }
  };

  const hasBeenChecked = (option: string, id: number) => {
    return (userAnswer[id] || []).find((o: string) => o === option);
  };

  const isValidAnswer = (question: any): boolean => {
    if (
      // question.answer_type_id === AnswerType.MULTI_SELECT &&
      question.answer_type_id !== AnswerType.EMPTY &&
      (!userAnswer[question.id] || userAnswer[question.id]?.length === 0)
    ) {
      const relatedParentQuestionLink = questionRelevants.find(
        (qr: any) => qr.show_question_id === question.id
      );

      if (
        relatedParentQuestionLink?.parent_question_id
      ) {
        const relatedParentQuestion = activeQuestions.find(
          (q) => q.id === relatedParentQuestionLink.parent_question_id
        );
        if (
          relatedParentQuestion.is_required &&
          relatedParentQuestion.answer_type_id === AnswerType.YESNO &&
          userAnswer[relatedParentQuestion.id] == relatedParentQuestionLink.condition
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const goNext = () => {
    setCheckErrors(true);
    let invalid = false;
    activeQuestions.forEach((q) => {
      if (
        q.is_required &&
        q.answer_type_id !== AnswerType.EMPTY &&
        (!userAnswer[q.id] || userAnswer[q.id]?.length === 0)
      ) {
        invalid = true;
      }
      if (!invalid) {
        invalid = !isValidAnswer(q);
      }
    });
    if (!invalid) {
      setStep(step + 1);
      setCheckErrors(false);
      fieldRef?.current?.scrollIntoView();
    }
  };

  const goBack = () => {
    setStep(step - 1);
    fieldRef?.current?.scrollIntoView();
  };

  const renderGroup = (question: any) => {
    if (
      question &&
      question.description === "Do you have safety awareness concerns?"
    ) {
      return "Safety Concerns";
    }
    return formData?.groups.find((g: any) => g.id === question.group_id)?.title;
  };

  const renderQuestion = (question: any) => {
    let error =
      checkErrors &&
      question.answer_type_id !== AnswerType.EMPTY &&
      question.is_required &&
      (!userAnswer[question.id] || userAnswer[question.id]?.length === 0);
    if (!error) {
      // check related question validation
      error = checkErrors && !isValidAnswer(question);
    }
    const ml = isMobile ? "ml-0" : "ml-10";

    switch (question.answer_type_id) {
      case AnswerType.TEXT:
        return (
          <div className={`font-sans block mt-3 ${ml}`}>
            <TextField
              placeholder={
                question.default_option?.indexOf("[PLACE_HOLDER]") !== -1
                  ? question.default_option?.replace("[PLACE_HOLDER]", "")
                  : ""
              }
              error={error}
              value={userAnswer[question.id]}
              onChange={(e: any) => setAnswers(e, question)}
              size="small"
              fullWidth
              variant="standard"
            />
            {error && <span className="text-xs text-red-600">required!</span>}
          </div>
        );

      case AnswerType.DATE:
        return (
          <div className={`font-sans block mt-3 ${ml}`}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="MM/dd/yyyy"
                value={
                  !userAnswer[question.id] ? null : userAnswer[question.id]
                }
                onChange={(e: any) => setAnswers(e, question)}
                renderInput={(params: any) => (
                  <TextField {...params} size="small" variant="standard" />
                )}
              />
            </LocalizationProvider>
            {error && (
              <span className="text-xs text-red-600 block">required!</span>
            )}
          </div>
        );

      case AnswerType.NUMBER:
        return (
          <div className={`font-sans block mt-3 ${ml}`}>
            <TextField
              error={error}
              type="number"
              value={userAnswer[question.id]}
              onChange={(e: any) => setAnswers(e, question)}
              size="small"
              variant="standard"
            />
            {error && (
              <span className="text-xs text-red-600 block">required!</span>
            )}
          </div>
        );

      case AnswerType.YESNO:
        return (
          <div className={`font-sans block mt-3 ${ml}`}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={userAnswer[question.id]}
              onChange={(e: any) => setAnswers(e, question)}
            >
              <FormControlLabel value="YES" control={<Radio />} label="Yes" />
              <FormControlLabel value="NO" control={<Radio />} label="No" />
            </RadioGroup>
            {error && (
              <span className="text-xs text-red-600 block">required!</span>
            )}
          </div>
        );

      case AnswerType.MULTI_SELECT:
        return (
          <div className={`font-sans block mt-3 ${ml}`}>
            {orderBy(question.AnswerOption, "id").map((option: any) => (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    onChange={(e: any) => handleMultipleAnswer(e, question)}
                    value={option.option}
                    checked={hasBeenChecked(option.option, question.id)}
                  />
                }
                label={option.option}
              />
            ))}
            {error && (
              <span className="text-xs text-red-600 block">required!</span>
            )}
          </div>
        );

      case AnswerType.SINGLE_SELECT:
        return (
          <div className={`font-sans block mt-3 ${ml}`}>
            <ButtonGroup
              size="small"
              aria-label="small button group"
              className={isMobile ? "mobil-single-select" : ""}
            >
              {orderBy(question.AnswerOption, "id").map((option: any) => (
                <Button
                  className={
                    userAnswer[question.id] === option.option
                      ? "bg-blue-500 text-white"
                      : ""
                  }
                  key={`btn-${option.id}`}
                  onClick={() => setAnswers(option.option, question)}
                >
                  {option.option}{" "}
                </Button>
              ))}
            </ButtonGroup>
            {error && (
              <span className="text-xs text-red-600 block">required!</span>
            )}
          </div>
        );
    }
  };

  const accept = async () => {
    const emailAddress = email.current?.value;
    if (emailAddress && validateEmail(emailAddress)) {
      // handle valid email
      const emailAddress = email.current?.value.toLowerCase();
      setValidUser(true);
      Spinner.show();
      const result = await httpPost("/app/user", { email: emailAddress }).catch(
        () => {
          setValidUser(false);
          return null;
        }
      );
      setUserEmail(emailAddress);

      if (result) {
        setUser(result);
        setRevisionId(`${result}-${generateUUID()}`);
      }

      Spinner.hide();
    } else {
      setEmailError(true);
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    document.location.href = "/login";
  };

  const returnDefaultOption = (option: string) => {
    if (option.indexOf("[PLACE_HOLDER]") !== -1) return "";
    switch (option) {
      case "TODAY":
        return formatDate(new Date(), "MM/DD/YYYY");
      default:
        return option;
    }
  };

  useEffect(() => {
    (async () => {
      const data = await httpGet(`/app/questions/${quesionnairId}`);

      let defOptions = {};
      (data?.questionnaire?.Question || [])
        .filter((q: any) => q.default_option)
        .map((q: any) => {
          defOptions = {
            ...defOptions,
            [q.id]: returnDefaultOption(q.default_option),
          };

          return null;
        });
      setUserAnswer(defOptions);

      setFormData(data);
    })();
  }, []);

  const getOverallText = (result: any) => {
    const overall = meanBy(result.groupWeight, "sss");
    const scope = result.overall.find(
      (v: any) => +overall >= v.from_score && +overall <= v.to_score
    );

    return scope?.result || "";
  };

  const getBucketName = (): string => {
    let bucketName = "";
    const dob = userAnswer["4"];
    const ageYearsDistinguisher = 6;
    const personCompleting = userAnswer["2"];
    const dateFormat = "MM/DD/YYYY";
    const ageInYears = moment().diff(moment(dob, dateFormat), "years");

    if (ageInYears < ageYearsDistinguisher) {
      if (personCompleting == parentGuardian) {
        bucketName = "Toddler Parent";
      } else {
        bucketName = "EI Professional";
      }
    } else {
      if (personCompleting == parentGuardian) {
        bucketName = "Parent - School Age Child";
      } else {
        bucketName = "Professional";
      }
    }

    return bucketName;
  };

  const goToUrl = async (result: any) => {
    const bucketName: string = getBucketName();
    const dobValue = userAnswer[+(process.env.REACT_APP_DOB || "4")];
    const nameOfPerson = userAnswer["100"];
    const childFirstName = userAnswer["3"];
    let selfCareScore: number = 0;
    let problemBehaviorScore: number = 0;
    let languageScore: number = 0;
    let scoresFlag: string = "";
    result.groupWeight.forEach((score: any) => {
      if (score.groupName === "Self-Care and Daily Living Tasks") {
        selfCareScore = score.sss.toFixed(0);
      } else if (score.groupName === "Problem Behavior ") {
        problemBehaviorScore = score.sss.toFixed(0);
      } else if (score.groupName === "Language and Learning Skills") {
        languageScore = score.sss.toFixed(0);
      }
    });
    const thresholdValue = 85;
    const overallScore: number = +meanBy(result.groupWeight, "sss").toFixed(0);

    if (selfCareScore < thresholdValue) {
      scoresFlag = "S";
    }
    if (languageScore < thresholdValue) {
      scoresFlag += "L";
    }
    if (problemBehaviorScore < thresholdValue) {
      scoresFlag += "P";
    }
    if (overallScore < thresholdValue) {
      scoresFlag += "O";
    }

    // Following parameters need to be passed to the url
    const queryParams: string = `?first_name=${nameOfPerson}&overall_score=${overallScore}&self_care_score=${selfCareScore}&language_score=${languageScore}&problem_score=${problemBehaviorScore}&bucket_name=${bucketName}&child_name=${childFirstName}&flag=${scoresFlag}`;

    if (dobValue) {
      const dob = new Date(new Date(dobValue).getTime() + 24 * 60 * 60 * 1000);
      const dobym = calcYearMonth(dob);

      if (dobym[0] <= 5) {
        document.location.href =
          process.env.REACT_APP_CHILD_URL + queryParams || "";
      } else {
        document.location.href =
          process.env.REACT_APP_YOUTH_URL + queryParams || "";
      }
    }
  };

  const acceptTermsAndConditions = (e: any) => {
    setAcceptTerms(e.target?.checked);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          {!isMobile && <Toolbar />}
          <Container
            style={isMobile ? { paddingLeft: 0, paddingRight: 0 } : {}}
          >
            {/* {submitted && result && (
              <Paper
                className={`block w-full p-10 text-left ${
                  isMobile ? "min-h-screen" : ""
                }`}
              >
                <Typography variant="h4" className="block mb-10">
                  <b>Assessment results: </b> {resultName}
                </Typography>

                <div className="mt-5">&nbsp;</div>
                {orderBy(result.groupWeight, "id", ["asc"]).map((w: any) => (
                  <div className="block mt-5">
                    <b>{w.groupName}</b> : {(+w.sss).toFixed(0)} %
                    <div className="block mt-4">
                      {resultName} {w.text}
                    </div>
                  </div>
                ))}
                <div className="block mt-10 mb-10 uppercase">
                  <b>Overal Score</b> :{" "}
                  {meanBy(result.groupWeight, "sss").toFixed(0)} %
                  <div className="block mt-4 normal-case">
                    {resultName} {getOverallText(result)}
                  </div>
                </div>

                <div className="block w-full mt-8 text-center">
                  <Button
                    size="large"
                    onClick={goToUrl}
                    variant="contained"
                    fullWidth={isMobile}
                  >
                    {" "}
                    Next{" "}
                  </Button>
                </div>
              </Paper>
            )} */}

            {!submitted && user === 0 && (
              <Paper className={`block w-full p-4 text-center`}>
                <div className="flex items-center">
                  <img src={logo} alt="" width={60} className="mr-2" />
                  <Typography
                    variant="h5"
                    className="cursor-pointer"
                    onClick={() => window.open("http://MaryBarbera.com")}
                  >
                    MaryBarbera.com
                  </Typography>
                </div>
                <Typography
                  variant={isMobile ? "h5" : "h4"}
                  className="text-center block oswald"
                >
                  Complete the 10-Minute
                </Typography>
                <Typography
                  variant={isMobile ? "h5" : "h4"}
                  className="justify-center items-center flex oswald"
                >
                 Barbera Early Childhood {" "} Assessment(BECA)™
                  {/* <div className="copy-right ml-1 mr-1">R</div>  */}
                </Typography>

                <div className="mt-1">&nbsp;</div>
                <div className="assessment-background"></div>
                <div className="mb-1">&nbsp;</div>

                {/* <div className="mt-10 mb-10">&nbsp;</div>
              <div className="mt-10 mb-10">&nbsp;</div> */}
                <div className="block mb-7">
                  <input onChange={acceptTermsAndConditions} type="checkbox" />{" "}
                  I have read and understand the{" "}
                  <span
                    onClick={() =>
                      window.open("https://marybarbera.com/privacy-policy/")
                    }
                    className="cursor-pointer btn-direction"
                  >
                    privacy policy{" "}
                  </span>
                </div>
                <div className="mb-3 text-center block">
                  <TextField
                    inputRef={email}
                    label={emailError ? "" : "Please enter your email"}
                    className={isMobile ? "w-full" : "w-1/2"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={handleEmailBlur}
                    onChange={handleEmailChange}
                    error={emailError}
                    helperText={
                      emailError ? "Please enter a valid email address" : ""
                    }
                  />
                </div>
                <Button
                  disabled={!acceptTerms}
                  className="btn-started"
                  onClick={accept}
                >
                  Let's get started
                </Button>
              </Paper>
            )}

            {!submitted && user !== 0 && (
              <Paper
                className={`block w-full p-4  ${
                  isMobile ? "min-h-screen overflow-x-hidden" : ""
                }`}
              >
                {!isMobile ? (
                  // <div className="flex items-center" ref={fieldRef}>
                  <div className="items-center text-center" ref={fieldRef}>
                    <img width={100} src={logo} alt="" />
                    <Typography
                      variant="h3"
                      // ml={10}
                      className="text-center block satisfy"
                      //  pl-20"
                    >
                      {formData?.questionnaire?.title}
                    </Typography>
                  </div>
                ) : (
                  <>
                    <img width={70} src={logo} alt="" />
                    <Typography variant="h4" className="text-center block pt-5">
                      {formData?.questionnaire?.title}
                    </Typography>
                  </>
                )}

                {!isMobile ? (
                  <Stepper
                    activeStep={step}
                    alternativeLabel
                    className="mt-10 mb-10"
                  >
                    {Array.apply(
                      null,
                      Array(formData?.questionnaire.steps + 1)
                    ).map((i, index) => (
                      <Step key={`step_${index + 1}`}>
                        <StepLabel>
                          {index === formData?.questionnaire.steps
                            ? "Results"
                            : `Step ${index + 1}`}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                ) : (
                  <div className="block mt-10 mb-10 text-center font-bold text-2xl text-sky-600">
                    {formData?.questionnaire.steps + 1 === step + 1 ? (
                      <span>Results</span>
                    ) : (
                      <span>
                        Step {step + 1} of {formData?.questionnaire.steps}
                      </span>
                    )}
                  </div>
                )}
                {activeQuestions.map((q: any, index) => (
                  <>
                    {(index === 0 ||
                      q.group_id !== activeQuestions?.[index - 1].group_id) && (
                      <div className="block mt-10">
                        <Typography
                          variant="h5"
                          className="border-b font-bold satisfy"
                        >
                          {renderGroup(q)}
                        </Typography>
                      </div>
                    )}

                    <div
                      key={q.id}
                      className={`block ${
                        q.answer_type_id === AnswerType.EMPTY ||
                        (index > 0 &&
                          activeQuestions?.[index - 1].answer_type_id ===
                            AnswerType.EMPTY)
                          ? ""
                          : "mt-10"
                      } ${!isMobile ? "pl-5" : "pl-0"} ${
                        q.is_hidden ? "hidden" : ""
                      }`}
                    >
                      {q.description !== "-" && (
                        <Typography
                          variant="h6"
                          className="mt-3 font-normal text-green-700 oswald"
                        >
                          {q.description}
                        </Typography>
                      )}

                      {renderQuestion(q)}
                    </div>
                  </>
                ))}

                {formData?.questionnaire.steps + 1 === step + 1 && (
                  <div className="block text-center pt-10">
                    <Typography variant="h5" className="block mt-10 font-bold">
                      Congratulations. You have completed the Assessment for{" "}
                      {resultName}.
                    </Typography>
                    <Typography variant="h5" className="block pt-10 font-bold">
                      If you need to go back and change any answers please do
                      that now.
                    </Typography>
                    <Typography variant="h5" className="block font-bold">
                      Otherwise, please continue to the results.
                    </Typography>
                  </div>
                )}

                {!isMobile && (
                  <div
                    className={`block mt-10 border-t ${
                      submitted && result ? "hidden" : ""
                    }`}
                  >
                    <div className="inline-block w-1/2">
                      <Button disabled={step === 0} onClick={goBack}>
                        Previous
                      </Button>
                    </div>

                    <div className="inline-block text-right w-1/2">
                      {formData?.questionnaire.steps + 1 === step + 1 ? (
                        <Button onClick={submitAnswers}>Continue</Button>
                      ) : (
                        <Button className="btn-direction" onClick={goNext}>
                          Next
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </Paper>
            )}

            {isMobile && (
              <div
                className={`block sticky bottom-0 bg-white border-t ${
                  (submitted && result) || (!submitted && user === 0)
                    ? "hidden"
                    : ""
                }`}
              >
                <div className="inline-block w-1/2">
                  <Button
                    className="btn-direction"
                    disabled={step === 0}
                    onClick={goBack}
                  >
                    Previous
                  </Button>
                </div>

                <div className="inline-block text-right w-1/2">
                  {formData?.questionnaire.steps + 1 === step + 1 ? (
                    <Button className="btn-direction" onClick={submitAnswers}>
                      Continue
                    </Button>
                  ) : (
                    <Button className="btn-direction" onClick={goNext}>
                      Next
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
