import moment from "moment";

export const formatTime = (date: Date, format = "hh:mm A") => {
    return moment(date).format(format);
}

export const formatDate = (date: Date, format = "LL") => {
    return moment(date).format(format);
}

export const formatDateTime = (date: Date, format = "MM-DD-YYYY HH:mm:ss") => {
    return moment(date).format(format);
}

export const calcYearMonth = (date: Date) => {
    const current = moment();
    const month = current.diff(date, 'months');
    return [Math.floor(month / 12), month % 12];
}