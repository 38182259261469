export enum AnswerType {
    TEXT = 1,
    DATE = 2,
    NUMBER = 3,
    LIST = 4,
    SINGLE_SELECT = 5,
    MULTI_SELECT = 6,
    YESNO = 7,
    SMALL_TEXT = 8,
    EMPTY = 0,
}